import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConstants } from "../common/app.constants";
import { Observable } from "rxjs";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
};
@Injectable({
    providedIn: 'root'
})
export class VanKienService {

    constructor(private http: HttpClient) { }

    getAllDistrict(): Observable<any> {
        return this.http.get(AppConstants.API_URL + 'district/list/noAuth', httpOptions);
    }

    getAllLibrary(): Observable<any> {
        return this.http.get(AppConstants.API_URL + 'library/list/noAuth', httpOptions);
    }

}