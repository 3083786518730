import { Component, OnInit } from '@angular/core';
import { District } from '../modals/district';
import { VanKienService } from '../_services/vankien.services';
import { map } from 'rxjs/operators';
import { Library } from '../modals/thuvien/library';
import { AppConstants } from '../common/app.constants';
import { Router } from '@angular/router';
import { ThuvienItem } from '../modals/thuvien/thuvien-item';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  districts: District[] = []
  libraries: Library[] = []
  libraryVideo: Library = {};
  libraryImage: Library = {};
  libraryDocument: Library = {};
  backendOrigin = AppConstants.API_URL2;
  videos: number = 0
  images: number = 0
  documents: number = 0

  constructor(private vankienService: VanKienService, private router: Router) {

  }

  ngOnInit() {
    this.getLibraries();
    this.getListDistrict();
  }

  getListDistrict() {
    this.vankienService.getAllDistrict().pipe(map((data: any) => data.resultData)).subscribe(
      response => {
        this.districts = response
      }, err => {

      }
    )

  }

  getLibraries() {
    this.vankienService.getAllLibrary().pipe(map((data: any) => data.resultData)).subscribe(
      response => {
        this.libraries = response
        this.libraries.map((lib) => {
          let name = lib.name;
          if (name == 'Video') {
            this.libraryVideo = lib;
            this.libraryVideo.libraryDocuments.map((lD) => {
              lD.libraryDocumentItems.map((lDItem) => {
                this.videos++
              })
            })
          } else if (name == 'Tài Liệu') {
            this.libraryDocument = lib;
            this.libraryDocument.libraryDocuments.map((lD) => {
              lD.libraryDocumentItems.map((lDItem) => {
                this.documents++
                lDItem.filePath = this.backendOrigin + lDItem.filePath
              })
            })
          } else if (name == 'Hình Ảnh') {
            this.libraryImage = lib;
            this.libraryImage.libraryDocuments.map((lD) => {
              lD.libraryDocumentItems.map((lDItem) => {
                this.images++
              })
            })
          }
        })
      }, err => { }
    )
  }

  openPreviewFile(lDItems: ThuvienItem){
    localStorage.setItem('filePath', lDItems.filePath);
    localStorage.setItem('fileName', lDItems.fileName);
    
    if (lDItems.filePath.endsWith('.pdf')) {
      window.open(lDItems.filePath, "_blank");
    } else {
      this.router.navigate(['/preview-file']);
    }
  }

  formatFilePathTemp(filePath: string): string {
    if (filePath.endsWith('.xls') || filePath.endsWith('.xlsx')) {
      return 'https://icons.iconarchive.com/icons/carlosjj/microsoft-office-2013/128/Excel-icon.png'
    } else if (filePath.endsWith('.csv')) {
      return 'https://icons.iconarchive.com/icons/oxygen-icons.org/oxygen/128/Mimetypes-text-csv-icon.png';
    } else if (filePath.endsWith('.doc') || filePath.endsWith('.docx')) {
      return 'https://icons.iconarchive.com/icons/carlosjj/microsoft-office-2013/128/Word-icon.png';
    } else if (filePath.endsWith('.pdf')) {
      return 'https://icons.iconarchive.com/icons/hopstarter/soft-scraps/128/Adobe-PDF-Document-icon.png';
    } else if (filePath.endsWith('.mp3') || filePath.endsWith('.wav')) {
      return 'https://icons.iconarchive.com/icons/wwalczyszyn/iwindows/128/Music-Library-icon.png';
    } else if (filePath.endsWith('.m4v') || filePath.endsWith('.mp4') || filePath.endsWith('.mov')) {
      return 'https://icons.iconarchive.com/icons/hadezign/hobbies/128/Movies-icon.png';
    } else if (filePath.endsWith('.png') || filePath.endsWith('.jpg') || filePath.endsWith('.jpeg')) {
      return 'https://icons.iconarchive.com/icons/gakuseisean/aire/128/Images-icon.png';
    } else {
      return 'https://icons.iconarchive.com/icons/hopstarter/mac-folders/128/Documents-icon.png';
    }
  }

  getExtensionFile(pathFile: string) {
    let extension = pathFile.match(/.[^.]+$/);
    return extension.toString().toUpperCase();
  }

  getFileName(fileName: string){
    let name = fileName.match(/(.*?)(.[^.]+)$/);
    return name && name[1];
  }

}
