// using for localhost
// export class AppConstants {
//     private static API_BASE_URL = "http://192.168.0.100:9099/";
//     private static API_BASE_URL2 = "http://192.168.0.100:9099";
//     public static API_URL = AppConstants.API_BASE_URL + "api/";
//     public static API_URL2 = AppConstants.API_BASE_URL2;
// }


// using for Production
export class AppConstants {
    private static API_BASE_URL = "http://103.154.176.83:1001/";
    private static API_BASE_URL2 = "http://103.154.176.83:1001";
    public static API_URL = AppConstants.API_BASE_URL + "api/";
    public static API_URL2 = AppConstants.API_BASE_URL2;
}