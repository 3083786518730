import { Component, OnInit } from '@angular/core';
import { District } from '../modals/district';
import { VanKienService } from '../_services/vankien.services';
import { map } from 'rxjs/operators';
import { Library } from '../modals/thuvien/library';
import { AppConstants } from '../common/app.constants';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-file',
  templateUrl: './preview-file.component.html',
  styleUrls: ['./preview-file.component.scss'],
})
export class PreviewFileComponent implements OnInit {

  backendOrigin = AppConstants.API_URL2;
  googleViewerUrl = 'https://docs.google.com/viewer?embedded=true&url=';
  localFilePath = localStorage.getItem('filePath');
  localFileName = localStorage.getItem('fileName');
  previewFile: SafeResourceUrl;
  previewFile2: SafeResourceUrl;

  constructor(private domSanitizer: DomSanitizer) {
    this.previewFile = this.domSanitizer.bypassSecurityTrustResourceUrl(this.localFilePath);
    this.previewFile2 = this.googleViewerUrl + this.localFilePath;

    // console.log(this.previewFile)
  }
  ngOnInit() {
  }

}
